@import './mixins.scss';
@import './variables.scss';
@import 'react-phone-input-2/lib/style.css';
@import 'react-big-calendar/lib/sass/styles';
@import 'react-datepicker';
@import '@sendbird/uikit-react/dist/index.css';

html {
  @media screen and (max-width: 450px) {
    &:root {
      touch-action: pan-x pan-y;
      height: 100%
    }
  }
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: $main-font-family;
  color: $defaultText;
  font-size: 12px;
  line-height: 1.2em;
  letter-spacing: 0.03em;

  &>iframe {
    display: none;
  }
}

html, body {
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  vertical-align: baseline;
}

a {
  text-decoration: none;
}

ol, ul {
  list-style: none; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


@media screen and (max-width: 450px) {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"] {
    font-size: 14px;
  }
}


::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: $defaultWhite;
}

::-webkit-scrollbar-thumb {
  background: $defaultGray;
  border-radius: 5px;
  transition: background 0.5s ease;
}
::-webkit-scrollbar-thumb:hover {
  background: $defaultLightGrey;
}

#sendbird-modal-root {
  position: absolute;
  z-index: 99;
}

.sendbird-conversation {
  border: none !important;
  border-radius: 24px;
}

.sendbird-conversation__messages-padding {
  @media #{$phones} {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.rbc-month-header {
  margin-top: 42px;
  color: $defaultText;
  border: none;

  .rbc-header {
    font-size: 14px;
    line-height: 1.2em;
    padding: 0;
    border: none;
  }
}

.rbc-month-view {
  border: none;
  gap: 24px;
  .rbc-day-bg {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid $defaultGray;
      border-radius: 4px;
    }
  }
  @media #{$tablets} {
    overflow: auto;
    & > div {
      width: 700px;
    }
  }
}

.rbc-time-view {
  @media #{$tablets} {
    overflow: auto;
    & > div {
      width: 700px;
    }
  }
}

.rbc-off-range-bg {
  background-color: $defaultWhite;
}

.rbc-month-row + .rbc-month-row {
  border: none;
}

.rbc-row-bg {
  .rbc-day-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: none;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
}
.rbc-row-segment {
  padding: 0 5px;
}
.rbc-date-cell {
  text-align: center;
  padding-top: 13px;
}
.rbc-time-view {
  border: none;
}
.rbc-today {
  background-color: $defaultWhite;
}

.rbc-allday-cell {
  .rbc-row-bg {
    gap: 0;
    .rbc-day-bg {
      border: none;
      border-left: 1px solid $defaultGray;
      border-radius: 0;
    }
  }
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  padding: 10px 2px;
  margin-bottom: 2px;
}

.css-1n6sfyn-MenuList {
  height: 110px;
}

.rbc-event-content {
  display: flex;
  align-items: center;
}
